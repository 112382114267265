




























































































































































































































import { Component, Prop, PropSync, Watch, Vue } from "vue-property-decorator";
//NOTE: there is a bug in the latest pdfvuer (19.05.2021, version 1.8.2) where there is a
//mismatch between compiled pdfjs version in pdfvuer (2.5.207) and pdfjs dependency installed by npm
//(2.7.570, where worker is loaded from)
//The workaround is to currently lock pdfvuer to 1.7.5 and manually added pdfjs as devdependency locked to v2.5.207
//When this is fixed, we can change to latest pdfvuer and remove pdfjs from devdependencies
import pdfvuer from "pdfvuer";
import "pdfjs-dist/build/pdf.worker.entry";
import net from "@/Scripts/app/net";

@Component({
  components: {
    Pdf: pdfvuer,
  },
})
export default class PdfViewer extends Vue {
  @Prop() readonly pdfSrc!: string;
  @Prop() readonly downloadUrl!: number;
  @Prop() readonly pdfTitle!: string;
  @Prop() readonly pdfErrorMsg!: string;
  @PropSync("pdfLoader", { type: Boolean, default: false }) pdfLoading!: boolean;

  pdfUrl: any = null;
  pdfLoaded = false;
  pdfError = false;
  pdfErrorMessage = "";
  numPages = 0;
  page = 1;
  pdfScale = 1;
  pdfFullscreenScale = 1;
  pdfFullscreen = false;
  pdfFullscreenSettingsbar = true;

  @Watch("pdfSrc")
  onPdfSrcChange(): void {
    if (this.pdfSrc) this.createPdf();
    this.setPdfHeight();
  }

  mounted(): void {
    window.addEventListener("resize", this.setPdfHeight);
  }

  setPdfHeight(): void {
    const pdfSheet = document.querySelector("#pdfContent");

    if (pdfSheet) {
      const pdfHeight = window.innerHeight - pdfSheet.getBoundingClientRect().top - 45;
      (pdfSheet as any).style.maxHeight = `${pdfHeight}px`;
    }
  }

  createPdf(): void {
    this.pdfScale = 1;
    this.pdfFullscreenScale = 1;
    this.pdfLoaded = false;
    this.pdfError = false;
    this.pdfLoading = true;
    this.pdfUrl = pdfvuer.createLoadingTask(this.pdfSrc);

    this.pdfUrl.then(
      (pdfO: any) => {
        this.numPages = pdfO.numPages;
        this.pdfLoaded = true;
        this.pdfLoading = false;
      },
      (error: any) => {
        this.pdfLoading = false;
        this.pdfError = true;
        this.pdfErrorMessage = error.message;
      }
    );
  }

  downloadPdf(): void {
    net.fileDownload(this.downloadUrl);
  }

  beforeDestroy(): void {
    window.removeEventListener("resize", this.setPdfHeight);
  }

  formatZoom(scale: number): string {
    return `${Math.floor(scale * 100)}%`;
  }
}
